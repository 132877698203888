import React from "react";

import { Main } from "~/layouts";
import { Seo } from "~/components/seo";
import { HeroTitle } from "~/components/hero";
import { HeroSubheadline } from "~/components/typography";
import { MarketingBlock, MarketingCopy } from "~/components/marketing";
import { Footer } from "~/components/footer";

const ShippingPoliciesPage = () => (
  <Main fullView>
    <Seo title="Shipping Policies" />
    <HeroTitle
      title="Shipping Policies"
      align="center"
    />

    <MarketingBlock>
      <HeroSubheadline>Shipping</HeroSubheadline>
    </MarketingBlock>
    <MarketingBlock>
      <MarketingCopy as="div">
        <p>We currently offer shipping through USPS. Cards shipped within the United States take around 3-7 days to arrive.</p>
        <p>Please allow 1 business day from the time of purchase for order processing.</p>
      </MarketingCopy>
    </MarketingBlock>

    {/*<MarketingBlock>
      <HeroSubheadline>International Shipping</HeroSubheadline>
    </MarketingBlock>
    <MarketingBlock>
      <MarketingCopy as="div">
        <p>We currently offer international shipping. International orders that are shipped take around 7-10 days to arrive — so plan accordingly!</p>
        <p>If you have any questions, please reach out to <a href="mailto:hello@thegoodsnail.com">hello@thegoodsnail.com</a></p>
      </MarketingCopy>
    </MarketingBlock>*/}

    <MarketingBlock>
      <HeroSubheadline>Returns</HeroSubheadline>
    </MarketingBlock>
    <MarketingBlock>
      <MarketingCopy as="div">
        <p>All sales on The Good Snail are final. There are no returns or refunds.</p>
        <p>For inquiries or customer service issues, please reach out to <a href="mailto:hello@thegoodsnail.com">hello@thegoodsnail.com</a></p>
      </MarketingCopy>
    </MarketingBlock>
    <Footer />
  </Main>
);

export default ShippingPoliciesPage;
